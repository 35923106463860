<template>
  <div style="height: 350px; padding-top: 15%">
    <div class="d-flex align-items-center justify-content-center">
      <b-spinner
        style="width: 1rem; height: 1rem"
        label="Large Spinner"
        variant="info"
        type="grow"
      ></b-spinner>
      <b-spinner
        style="width: 1.5rem; height: 1.5rem"
        label="Large Spinner"
        variant="info"
        type="grow"
      ></b-spinner>
      <b-spinner
        style="width: 2rem; height: 2rem"
        label="Large Spinner"
        variant="info"
        type="grow"
      ></b-spinner>
      <b-spinner
        style="width: 1.5rem; height: 1.5rem"
        label="Large Spinner"
        variant="info"
        type="grow"
      ></b-spinner>
      <b-spinner
        style="width: 1rem; height: 1rem"
        label="Large Spinner"
        variant="info"
        type="grow"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>
