<template>
  <div class="package-detail-page">
    <Carousel />
    <PackagesDetail />
    <RegisterAndLogin />
    <ForgotPassword />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestionCircle,
  faStar,
  faQuoteRight,
  faArrowRight,
  faExternalLinkAlt,
  faDownload,
  faBook,
  faLock,
  faShapes,
  faAlignCenter,
  faTable,
  faMapMarkedAlt,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookSquare,
  faTwitterSquare,
  faDribbble,
  faGithubSquare,
  faAngular,
  faVuejs,
  faReact,
  faHtml5,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
// import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(
  faQuestionCircle,
  faStar,
  faQuoteRight,
  faArrowRight,
  faFacebookSquare,
  faTwitterSquare,
  faDribbble,
  faGithubSquare,
  faAngular,
  faVuejs,
  faReact,
  faHtml5,
  faExternalLinkAlt,
  faDownload,
  faBook,
  faLock,
  faFacebook,
  faShapes,
  faAlignCenter,
  faTable,
  faMapMarkedAlt,
  faSitemap,
);

import Carousel from '../../Layout/home/carousel';
import PackagesDetail from '../../Layout/package_detail/package_detail';
import RegisterAndLogin from '../../Layout/home/register_and_login';
import ForgotPassword from '../../Layout/home/forgot_password';
export default {
  name: 'packagesList',
  components: {
    Carousel,
    PackagesDetail,
    RegisterAndLogin,
    ForgotPassword,
  },
};
</script>
<style type="text/css" scoped>
.package-detail-page {
  background-color: #e0eaeb47;
}
</style>
